import { SET_PANDITS } from '../constants'

const panditsList = []
export const panditReducer = (state = panditsList, action) => {
  switch (action.type) {
    case SET_PANDITS:
      return action.data.data
    default:
      return state
  }
}
