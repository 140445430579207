import { SET_TESTIMONIALS } from '../constants'

const testimonials = []
export const testimonialReducer = (state = testimonials, action) => {
  switch (action.type) {
    case SET_TESTIMONIALS:
      return action.data.data
    default:
      return state
  }
}
