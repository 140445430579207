import { SET_PACKAGES } from '../constants'

const allPackages = []
export const allPackageReducer = (state = allPackages, action) => {
  switch (action.type) {
    case SET_PACKAGES:
      return action.data.data
    default:
      return state
  }
}
