import { SET_ABOUTUS } from '../constants'

const allAboutus = []
export const aboutusReducer = (state = allAboutus, action) => {
  switch (action.type) {
    case SET_ABOUTUS:
      return action.data.data
    default:
      return state
  }
}
