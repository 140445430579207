import { put, takeEvery } from 'redux-saga/effects'
import {
  ROLES,
  SET_ROLES,
  USERS,
  SET_USERS,
  CATEGORIES,
  SET_CATEGORIES,
  PRODUCTS,
  SET_PRODUCTS,
  FAQS,
  SET_FAQS,
  BASIC_SETUP,
  SET_BASIC_SETUP,
  TESTIMONIALS,
  SET_TESTIMONIALS,
  STATES,
  SET_STATES,
  CITIES,
  SET_CITIES,
  LANGUAGES,
  SET_LANGUAGES,
  PUJAS,
  SET_PUJAS,
  PANDITS,
  SET_PANDITS,
  PANDIT_EDUCATIONS,
  SET_PANDIT_EDUCATIONS,
  ALLORDERS,
  SET_ALLORDERS,
  ALLBOOKINGS,
  SET_ALLBOOKINGS,
  PACKAGES,
  SET_PACKAGES,
  BLOGS,
  SET_BLOGS,
  CONTACT_REQUESTS,
  SET_CONTACT_REQUESTS,
  ABOUTUS,
  SET_ABOUTUS,
  CHATANDCALL_REQUESTS,
  SET_CHATANDCALL_REQUESTS,
  PACKAGEADDONS,
  SET_PACKAGEADDONS,
} from './constants'
import globalVariable from 'src/globalVariable'

function* rolesList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/roles/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()

    yield put({ type: SET_ROLES, data })
  } catch (error) {}
}
function* usersList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/user/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()

    yield put({ type: SET_USERS, data })
  } catch (error) {}
}
function* categoriesList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/categories/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()

    yield put({ type: SET_CATEGORIES, data })
  } catch (error) {}
}
function* productsList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/products/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()

    yield put({ type: SET_PRODUCTS, data })
  } catch (error) {}
}
function* faqsList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/faqs/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()
    yield put({ type: SET_FAQS, data })
  } catch (error) {}
}
function* getAllPackageAddOns() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/packageaddons/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()
    yield put({ type: SET_PACKAGEADDONS, data })
  } catch (error) {}
}
function* getBasicSetupDetails() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/basic_details/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()
    yield put({ type: SET_BASIC_SETUP, data })
  } catch (error) {}
}

function* getTestimonialList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/testimonials/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()
    yield put({ type: SET_TESTIMONIALS, data })
  } catch (error) {}
}

function* getStatesList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/states/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()
    yield put({ type: SET_STATES, data })
  } catch (error) {}
}

function* getCitiesList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/cities/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()
    yield put({ type: SET_CITIES, data })
  } catch (error) {}
}
function* getLanguagesList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/languages/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()
    yield put({ type: SET_LANGUAGES, data })
  } catch (error) {}
}
function* getPujasList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/pujas/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()
    yield put({ type: SET_PUJAS, data })
  } catch (error) {}
}
function* getPanditsList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/pandits/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()
    yield put({ type: SET_PANDITS, data })
  } catch (error) {}
}
function* getPanditEducationsList() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/pandit_educations/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()
    yield put({ type: SET_PANDIT_EDUCATIONS, data })
  } catch (error) {}
}

function* getAllOrders() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/all_orders/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()
    // console.log('====================================')
    console.log(data)
    console.log('====================================')
    yield put({ type: SET_ALLORDERS, data })
  } catch (error) {}
}
function* getAllBookings() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/all_bookings/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()

    yield put({ type: SET_ALLBOOKINGS, data })
  } catch (error) {}
}
function* getAllPackages() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/packages/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()

    yield put({ type: SET_PACKAGES, data })
  } catch (error) {}
}
function* getAllBlogs() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/blogs/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()

    yield put({ type: SET_BLOGS, data })
  } catch (error) {}
}
function* getAllContactRequests() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/contact_requests/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()
    yield put({ type: SET_CONTACT_REQUESTS, data })
  } catch (error) {}
}
function* getAllChatAndCallRequests() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/chatandcall_requests/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()
    yield put({ type: SET_CHATANDCALL_REQUESTS, data })
  } catch (error) {}
}
function* getAllAaboutUs() {
  try {
    const url = `${process.env.REACT_APP_BACKEND_1}/admin/aboutus/getAll`

    let data = yield fetch(url, {
      headers: new Headers({
        Authorization: `Bearer ${globalVariable.accessToken}`,
      }),
    })
    data = yield data.json()
    yield put({ type: SET_ABOUTUS, data })
  } catch (error) {}
}

function* SagaData() {
  yield takeEvery(ROLES, rolesList)
  yield takeEvery(USERS, usersList)
  yield takeEvery(CATEGORIES, categoriesList)
  yield takeEvery(PRODUCTS, productsList)
  yield takeEvery(FAQS, faqsList)
  yield takeEvery(BASIC_SETUP, getBasicSetupDetails)
  yield takeEvery(TESTIMONIALS, getTestimonialList)
  yield takeEvery(STATES, getStatesList)
  yield takeEvery(CITIES, getCitiesList)
  yield takeEvery(LANGUAGES, getLanguagesList)
  yield takeEvery(PUJAS, getPujasList)
  yield takeEvery(PANDITS, getPanditsList)
  yield takeEvery(PANDIT_EDUCATIONS, getPanditEducationsList)
  yield takeEvery(ALLORDERS, getAllOrders)
  yield takeEvery(ALLBOOKINGS, getAllBookings)
  yield takeEvery(PACKAGES, getAllPackages)
  yield takeEvery(BLOGS, getAllBlogs)
  yield takeEvery(CONTACT_REQUESTS, getAllContactRequests)
  yield takeEvery(CHATANDCALL_REQUESTS, getAllChatAndCallRequests)
  yield takeEvery(ABOUTUS, getAllAaboutUs)
  yield takeEvery(PACKAGEADDONS, getAllPackageAddOns)
}

export default SagaData
