import { SET_PUJAS } from '../constants'

const allPujas = []
export const pujaReducer = (state = allPujas, action) => {
  switch (action.type) {
    case SET_PUJAS:
      return action.data.data
    default:
      return state
  }
}
