import { SET_PACKAGEADDONS } from '../constants'

const allPackageAddOns = []
export const packageAddOnsReducer = (state = allPackageAddOns, action) => {
  switch (action.type) {
    case SET_PACKAGEADDONS:
      return action.data.data
    default:
      return state
  }
}
