import { SET_CHATANDCALL_REQUESTS } from '../constants'

const allChatAndCallRequests = []
export const chatandcallContactRequestReducer = (state = allChatAndCallRequests, action) => {
  switch (action.type) {
    case SET_CHATANDCALL_REQUESTS:
      return action.data.data
    default:
      return state
  }
}
