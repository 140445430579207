import { SET_BASIC_SETUP } from '../constants'

const websiteBasicSetup = {}
export const basicDetailReducer = (state = websiteBasicSetup, action) => {
  switch (action.type) {
    case SET_BASIC_SETUP:
      return action.data.data
    default:
      return state
  }
}
