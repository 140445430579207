import { SET_ALLBOOKINGS } from '../constants'

const allBookings = []
export const allBookingReducer = (state = allBookings, action) => {
  switch (action.type) {
    case SET_ALLBOOKINGS:
      return action.data.data
    default:
      return state
  }
}
