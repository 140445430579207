import { SET_ALLORDERS } from '../constants'

const allOrders = []
export const allOrdersReducer = (state = allOrders, action) => {
  switch (action.type) {
    case SET_ALLORDERS:
      return action.data.data
    default:
      return state
  }
}
