import { SET_LANGUAGES } from '../constants'

const languageList = []
export const languageReducer = (state = languageList, action) => {
  switch (action.type) {
    case SET_LANGUAGES:
      return action.data.data
    default:
      return state
  }
}
