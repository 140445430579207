import { SET_STATES } from '../constants'

const states = []
export const stateReducer = (state = states, action) => {
  switch (action.type) {
    case SET_STATES:
      return action.data.data
    default:
      return state
  }
}
