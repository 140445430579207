import { SET_PANDIT_EDUCATIONS } from '../constants'

const allPanditEducations = []
export const panditEducationReducer = (state = allPanditEducations, action) => {
  switch (action.type) {
    case SET_PANDIT_EDUCATIONS:
      return action.data.data
    default:
      return state
  }
}
